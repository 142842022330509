import React, { useState, useEffect } from "react";
import { Accordion, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import EnsureFileDownloadModal from "./EnsureFileDownloadModal/EnsureFileDownloadModal";
import DeleteFileModal from "./DeleteFileModal/DeleteFileModal";
import EditImageModal from "./EditImageModal/EditImageModal";
import QualificationVerificationModal from "./QualificationVerificationModal/QualificationVerificationModal";
import VerificationModal from "./VerificationModal/VerificationModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../Widgets/Loader/Loader";
import {
  postAddiotionalRequestInformationForSubmittedApplicationsOfApplicationManager,
  postSubmitVerificationQualificationDocumentForSubmittedApplicationsOfApplicationManager,
  postRequestAgainTRAForSubmittedApplicationsOfApplicationManager,
} from "../../../../api";
import AddImageModal from "../Stage1/AddImageModal/AddImageModal";

const Stage_3_R = ({
  current_stage,
  is_doc_deleted,
  ApiPageData,
  pointerID,
  getAllDataOfSubmittedApplicationsFunction,
}) => {
  const stage = "stage_3_R";

  const [loading, setLoading] = useState(false);
  const accountType = localStorage.getItem("AQATO_SuperAdminAccountType") || "";

  const [documents, setDocuments] = useState([]);
  const [showCommentsInputs, setShowCommentsInputs] = useState({});
  const [comments, setComments] = useState({});
  const [newDocument, setNewDocument] = useState("");

  const [requestAddBTN_Disabled, setrequestAddBTN_Disabled] = useState(true);

  const [showEnsureFileDownloadModal, setShowEnsureFileDownloadModal] =
    useState(false);
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [showEditImageModal, setShowEditImageModal] = useState(false);
  const [showAddImageModal, setShowAddImageModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [
    showQualificationVerificationModal,
    setShowQualificationVerificationModal,
  ] = useState(false);

  // State to track if the comment input is enabled
  const [commentInputEnabled, setCommentInputEnabled] = useState(true);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  useEffect(() => {
    setDocuments(ApiPageData?.db_files);
  }, [ApiPageData]);

  const handleEnsureFileDownloadOpen = () => {
    setShowEnsureFileDownloadModal(true);
  };

  const handleEnsureFileDownloadClose = () => {
    setShowEnsureFileDownloadModal(false);
  };

  const handleDeleteFileOpen = (doc) => {
    setSelectedDoc(doc);
    setShowDeleteFileModal(true);
  };

  const handleDeleteFileClose = () => {
    setShowDeleteFileModal(false);
  };

  const handleQualificationVerificationModalShow = () => {
    setShowQualificationVerificationModal(true);
  };

  const handleQualificationVerificationModalClose = () => {
    setShowQualificationVerificationModal(false);
  };

  const handleEditImageOpen = (doc) => {
    setSelectedDoc(doc);
    setShowEditImageModal(true);
    // Enable comment input when the pencil icon is clicked
    setCommentInputEnabled(true);
  };

  const handleEditImageClose = () => {
    setShowEditImageModal(false);
  };


  const handleAddImageClose = () => {
    setShowAddImageModal(false);
  };


  const handleShowInput = (docId) => {
    setShowCommentsInputs((prevState) => ({
      ...prevState,
      [docId]: !prevState[docId],
    }));
  };

  const handleCommentChange = (docId, value) => {
    setComments((prevState) => ({
      ...prevState,
      [docId]: value,
    }));

    if (newDocument.trim() === "") {
      setrequestAddBTN_Disabled(false);
    } else {
      setrequestAddBTN_Disabled(true);
    }
  };

  const handleAddNewDocument = () => {
    const newDocId = `new-doc-${Date.now()}`;
    const newDoc = {
      document_id: newDocId,
      document_name: "[T.B.A.] -",
      is_additional: true,
      document_link: null,
    };

    setrequestAddBTN_Disabled(false);

    // Add the new document to the end of the existing documents
    setDocuments((prevState) => [newDoc, ...prevState]);

    // Initialize the state for the new document with comments input hidden
    setShowCommentsInputs((prevState) => ({
      ...prevState,
      [newDocId]: false, // Set to false so the bi-chat-left-dots icon is shown
    }));

    setComments((prevState) => ({
      ...prevState,
      [newDocId]: newDocument,
    }));

    setNewDocument(""); // Clear the input field
  };

  const handleRequestAdditionalInfo = async () => {
    // documents.forEach((doc) => {
    //   console.log("Document:");
    //   console.log(`  Name: ${doc.document_name}`);
    //   console.log(`  ID: ${doc.document_id}`);
    //   console.log(`  Is Additional: ${doc.is_additional ? "Yes" : "No"}`);
    //   console.log(`  Comments: ${comments[doc.document_id] || "No comments"}`);
    // });

    const documentNames = [];
    const documentIds = [];
    const isAdditional = [];
    const documentComments = [];

    // Populate arrays with document data
    documents.forEach((doc) => {
      const comment = comments[doc?.document_id] || "No comments";

      // Only add documents with comments other than "No comments"
      if (comment !== "No comments") {
        documentNames.push(doc.document_name);
        documentIds.push(doc.document_id);
        isAdditional.push(doc.is_additional ? "Yes" : "No");
        documentComments.push(comment);
      }
    });

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);

    // Append arrays to formData with single keys
    formData.append("document_names", JSON.stringify(documentNames));
    formData.append("document_ids", JSON.stringify(documentIds));
    formData.append("is_additional", JSON.stringify(isAdditional));
    formData.append("document_comments", JSON.stringify(documentComments));

    try {
      setLoading(true);

      const response =
        await postAddiotionalRequestInformationForSubmittedApplicationsOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Requested Successfully."
        );
        handleVerificationModalClose();

        getAllDataOfSubmittedApplicationsFunction(pointerID);
      } else {
        setLoading(false);
        console.error(
          "Error in requesting:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in requesting"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in requesting:", error);
    }
  };

  const handleRequestAdditionalInfoModalOpen = () => {
    setShowVerificationModal(true);
  };

  const handleVerificationModalClose = () => {
    setShowVerificationModal(false);
  };

  const handleVerificationModalYes = () => {
    handleRequestAdditionalInfo();
    handleVerificationModalClose();
  };

  // State for file input and error handling
  const [formValues, setFormValues] = useState({
    file: null,
    error: "",
    fileError: false,
  });

  const [showTRAModal, setShowTRAModal] = useState(false);

  const handleTRAOpen = (doc) => {
    setSelectedDoc(doc);
    setShowTRAModal(true);
  };

  const handleTRAClose = () => {
    setShowTRAModal(false);
    setSelectedDoc(null);
  };

  const handleRequestAgainTRA = async (doc) => {
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);
    formData.append("document_id", doc?.document_id);
    formData.append("document_name", doc?.document_name);

    try {
      setLoading(true);

      const response =
        await postRequestAgainTRAForSubmittedApplicationsOfApplicationManager(
          // formData,
          pointerID
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg || "Requested Successfully."
        );
        handleTRAClose();

        // getAllDataOfSubmittedApplicationsFunction(pointerID);
      } else {
        setLoading(false);
        console.error(
          "Error in requesting:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in requesting"
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in requesting:", error);
    }
  };

  // Handler for file input change
  const handleFileChangeForVerificationQualification = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== "application/pdf") {
        // Clear the file input and show an error message if the file is not a PDF
        event.target.value = ""; // Clear the file input
        setFormValues({
          file: null,
          error: "Only PDF files are allowed.",
          fileError: true,
        });
      } else {
        // Update the state if the file is a PDF
        setFormValues({
          file,
          error: "",
          fileError: false,
        });
      }
    }
  };

  // Handler for form submission
  const handleSubmitVerificationQualificationDocument = async (event) => {
    event.preventDefault();
    if (!formValues.file) {
      setFormValues((prevState) => ({
        ...prevState,
        fileError: true,
        error: "Please upload a valid PDF file.",
      }));
      return;
    }

    // Create a new FormData object
    const formData = new FormData();
    formData.append("pointer_id", pointerID);
    formData.append("stage", stage);
    formData.append("file", formValues.file);

    try {
      setLoading(true);

      const response =
        await postSubmitVerificationQualificationDocumentForSubmittedApplicationsOfApplicationManager(
          formData
        );

      setLoading(false);

      if (
        response &&
        response?.data &&
        response?.data?.response &&
        response?.data?.response?.response === true &&
        response?.data?.response?.data
      ) {
        toast.success(
          response?.data?.response?.success_msg ||
            "Verification Requested Successfully."
        );

        getAllDataOfSubmittedApplicationsFunction(pointerID);

        setFormValues({
          file: null,
          error: "",
          fileError: false,
        });
      } else {
        setLoading(false);
        console.error(
          "Error in Verification requesting:",
          response?.data?.response?.error_msg || "Unknown error"
        );
        toast.error(
          response?.data?.response?.error_msg || "Error in uploading."
        );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in Verification requesting:", error);
      setFormValues((prevState) => ({
        ...prevState,
        error: "An error occurred while uploading the file.",
      }));
    }
  };

  const handleDeleteExtraAddedComment = (doc) => {
    if (!doc || !doc.document_id) return;

    // Capture the comment before deleting
    const commentToDelete = comments[doc.document_id];

    // Filter out the document with the matching document_id
    setDocuments((prevState) =>
      prevState.filter((document) => document.document_id !== doc.document_id)
    );

    // Remove comments associated with the deleted document
    setShowCommentsInputs((prevState) => {
      const updatedState = { ...prevState };
      delete updatedState[doc.document_id];
      return updatedState;
    });

    setComments((prevState) => {
      const updatedComments = { ...prevState };
      delete updatedComments[doc.document_id];
      return updatedComments;
    });

    // Show toast with the deleted comment
    toast.success(`Deleted comment: ${commentToDelete}`);
  };

  return (
    <>
      {loading && <Loader />}

      <Accordion.Item eventKey="5">
        <Accordion.Header className="accordion_header">
          <i className="bi bi-folder-fill mx-2"></i> Stage 3 - Technical
          Interview (Reassessment)
        </Accordion.Header>
        <Accordion.Body>
          <>
            <Button
              onClick={handleEnsureFileDownloadOpen}
              className="btn_yellow_green btn mb-3"
            >
              Download All Stage 3 (Reassessment) Documents <i className="bi bi-download"></i>
            </Button>


            {accountType !== "head_office" && (
              <Button
                onClick={() => {
                  setShowAddImageModal(true);
                }}
                className="btn_yellow_green btn mb-3 float-end"
                disabled={(current_stage !== stage ) ? true : false}
              >
                <i class="bi bi-file-earmark-plus"></i>
                Add New Document
              </Button>
              )}

            <table
              className="table table-striped table-bordered w-100"
              style={{
                borderRadius: "10px",
                overflow: "hidden",
                borderCollapse: "separate",
                borderSpacing: 0,
                border: "0.5px solid #fecc00",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#333",
                    color: "white",
                    cursor: "default",
                  }}
                >
                  <th className="col-md-4 col-4">Document Name</th>
                  <th className="col-md-2 col-2 text-center">Action</th>
                  <th className="col-md-6 col-6">Comments</th>
                </tr>
              </thead>
              <tbody>
                {documents
                  .filter((doc) => !doc?.document_id?.startsWith("new-doc-"))
                  .map((doc, index) => {
                    const isDisabled = doc?.process_requested_file === true;
                    const removedFile = (doc?.document_link === "javascript:void(0)") ? true : false;

                    return (
                      <tr
                        key={index}
                        style={{
                          pointerEvents: isDisabled ? "none" : "auto",
                          opacity: isDisabled ? 0.5 : 1,
                        }}
                      >
                        <td className="w-50">
                          <a
                            href={doc?.document_link}
                            target={(doc?.document_link === "javascript:void(0)") ? "" : "_blank"}
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: doc?.document_link
                                ? "none"
                                : "default",
                              cursor: doc?.document_link
                                ? "pointer"
                                : "default",
                            }}
                            onMouseEnter={(e) => {
                              if (doc?.document_link) {
                                e.currentTarget.style.textDecoration =
                                  "underline";
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (doc?.document_link) {
                                e.currentTarget.style.textDecoration = "none";
                              }
                            }}
                            className={(removedFile === true) ? "opacity-low" : ""}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {doc?.document_name}
                                {doc?.is_additional !== "" && (
                                  <span
                                    style={{
                                      fontWeight: "normal",
                                      color: "black",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    (Additional Information)
                                  </span>
                                )}
                              </div>

                              {doc?.process_requested_file === true && (
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  style={{
                                    color: "#055837",
                                    cursor: "default",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </span>
                          </a>
                        </td>

                        <td
                          className="d-flex justify-content-center"
                          style={{ textAlign: "center" }}
                        >
                          <a
                            href={doc?.document_link}
                            download={doc?.document_name}
                            className={(removedFile === true) ? "btn btn-sm btn_yellow_green me-1 opacity-low" : "btn btn-sm btn_yellow_green me-1"}
                            target={(doc?.document_link === "javascript:void(0)") ? "" : "_blank"}
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-download"></i>
                          </a>
                          {accountType !== "head_office" && (
                            <button
                              type="button"
                              className="btn btn-sm btn_green_yellow me-1"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() => {
                                setIsRequired(true);
                                handleEditImageOpen(doc);
                              }}
                              style={{
                                cursor: isDisabled ? "not-allowed" : "pointer",
                              }}
                              disabled={
                                isDisabled || doc?.edit_file_disable === true || (removedFile === true && is_doc_deleted == "1")
                              }
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                          )}

                          <button
                            href="javascript:void(0)"
                            className="btn btn-sm btn-danger me-1"
                            onClick={() => handleDeleteFileOpen(doc)}
                            style={{
                              cursor: isDisabled ? "not-allowed" : "pointer",
                            }}
                            disabled={
                              isDisabled ||
                              doc?.document_name === "TRA Application Form" ||
                              doc?.delete_file_disable === true ||
                              (removedFile === true && is_doc_deleted == "1") ||
                              doc?.is_required === "1"
                            }
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>

                          <button
                            href="javascript:void(0)"
                            className="btn btn-sm btn_green_yellow me-2"
                            onClick={() => {
                              if (
                                doc?.document_name === "TRA Application Form"
                              ) {
                                handleTRAOpen(doc);
                              } else {
                                handleShowInput(doc?.document_id);
                              }
                            }}
                            style={{
                              cursor: isDisabled ? "not-allowed" : "pointer",
                            }}
                            disabled = {(removedFile === true && is_doc_deleted == "1")}
                            // disabled={
                            //   isDisabled || doc?.comment_btn_disable === true
                            // }
                          >
                            {doc?.document_name === "TRA Application Form" ? (
                              <i className="bi bi-arrow-clockwise"></i>
                            ) : (
                              <i
                                className={`bi ${
                                  showCommentsInputs[doc?.document_id]
                                    ? "bi-check-lg"
                                    : "bi-chat-left-dots"
                                }`}
                              ></i>
                            )}
                          </button>
                        </td>

                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={comments[doc?.document_id] || ""}
                            onChange={(e) =>
                              handleCommentChange(
                                doc?.document_id,
                                e.target.value
                              )
                            }
                            disabled={
                              !showCommentsInputs[doc?.document_id] ||
                              isDisabled
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}

                {/* New Document Row - Always shown at the end */}
                <tr>
                <td className="w-50" style={{ textAlign: "left", verticalAlign: "middle" }}>
  <span
    style={{
      fontWeight: "bold",
      color: "#055837",
      cursor: "default",
    }}
  >
    Additional Information
  </span>
</td>

<td
  className="d-flex justify-content-center align-items-center"
  style={{ textAlign: "center", height: "100%" }} // Full height for vertical centering
>
                    <button
                      type="button"
                       className="btn btn-sm btn_green_yellow mt-1"
                      onClick={() => handleAddNewDocument()}
                      disabled={!newDocument.trim()}
                      style={{ height: "35px" }}
                    >
                      <i className="bi bi-plus-circle"></i> Add &rarr;
                    </button>
                  </td>

                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={newDocument}
                      onChange={(e) => {
                        setNewDocument(e.target.value);
                        setrequestAddBTN_Disabled(true);
                      }}
                      
                      disabled={!commentInputEnabled}
                      style={{
                        border: "2px solid #fecc00",
                        borderRadius: "5px",
                        padding: "0.375rem 0.75rem",
                      }}
                    />
                  </td>
                </tr>
                {/*  */}

                {documents
                  .filter((doc) => doc?.document_id?.startsWith("new-doc-"))
                  .map((doc, index) => {
                    const isDisabled = doc?.process_requested_file === true;
                    const removedFile = (doc?.document_link === "javascript:void(0)") ? true : false;

                    return (
                      <tr
                        key={index}
                        style={{
                          pointerEvents: isDisabled ? "none" : "auto",
                          opacity: isDisabled ? 0.5 : 1,
                        }}
                      >
                        <td className="w-50">
                          <a
                            href={doc?.document_link}
                            target={(doc?.document_link === "javascript:void(0)") ? "" : "_blank"}
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: doc?.document_link
                                ? "none"
                                : "default",
                              cursor: doc?.document_link
                                ? "pointer"
                                : "default",
                            }}
                            onMouseEnter={(e) => {
                              if (doc?.document_link) {
                                e.currentTarget.style.textDecoration =
                                  "underline";
                              }
                            }}
                            onMouseLeave={(e) => {
                              if (doc?.document_link) {
                                e.currentTarget.style.textDecoration = "none";
                              }
                            }}
                            className={(removedFile === true) ? "opacity-low" : ""}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                {doc?.document_name}
                                {doc?.is_additional !== "" && (
                                  <span
                                    style={{
                                      fontWeight: "normal",
                                      color: "black",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    (Additional Information)
                                  </span>
                                )}
                              </div>

                              {doc?.process_requested_file === true && (
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  style={{
                                    color: "#055837",
                                    cursor: "default",
                                  }}
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </span>
                          </a>
                        </td>

                        <td
                          className="d-flex justify-content-center"
                          style={{ textAlign: "center" }}
                        >
                          <a
                            href={doc?.document_link}
                            download={doc?.document_name}
                            className={(removedFile === true) ? "btn btn-sm btn_yellow_green me-1 opacity-low" : "btn btn-sm btn_yellow_green me-1"}
                            target={(doc?.document_link === "javascript:void(0)") ? "" : "_blank"}
                            rel="noopener noreferrer"
                            disabled={doc?.document_id?.startsWith("new-doc-")}
                          >
                            <i className="bi bi-download"></i>
                          </a>

                          {accountType !== "head_office" && (
                            <button
                              type="button"
                              className="btn btn-sm btn_green_yellow me-1"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() => handleEditImageOpen(doc)}
                              style={{
                                cursor: isDisabled ? "not-allowed" : "pointer",
                              }}
                              disabled={
                                isDisabled ||
                                doc?.edit_file_disable === true ||
                                doc?.document_id?.startsWith("new-doc-") ||
                                (removedFile === true && is_doc_deleted == "1")
                              }
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                          )}

                          <button
                            href="javascript:void(0)"
                            className="btn btn-sm btn-danger me-1"
                            // onClick={() => handleDeleteFileOpen(doc)}
                            onClick={() => handleDeleteExtraAddedComment(doc)}
                            style={{
                              cursor: isDisabled ? "not-allowed" : "pointer",
                            }}
                            disabled={
                              isDisabled ||
                              doc?.document_name === "TRA Application Form" ||
                              doc?.delete_file_disable === true ||
                              (removedFile === true && is_doc_deleted == "1")
                            }
                          >
                            <i className="bi bi-trash-fill"></i>
                          </button>

                          <button
                            href="javascript:void(0)"
                            className="btn btn-sm btn_green_yellow me-2"
                            onClick={() => {
                              if (
                                doc?.document_name === "TRA Application Form"
                              ) {
                                handleTRAOpen(doc);
                              } else {
                                handleShowInput(doc?.document_id);
                              }
                            }}
                            style={{
                              cursor: isDisabled ? "not-allowed" : "pointer",
                            }}
                            disabled = {(removedFile === true && is_doc_deleted == "1")}
                            // disabled={
                            //   isDisabled || doc?.comment_btn_disable === true
                            // }
                          >
                            {doc?.document_name === "TRA Application Form" ? (
                              <i className="bi bi-arrow-clockwise"></i>
                            ) : (
                              <i
                                className={`bi ${
                                  showCommentsInputs[doc?.document_id]
                                    ? "bi-check-lg"
                                    : "bi-chat-left-dots"
                                }`}
                              ></i>
                            )}
                          </button>
                        </td>

                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={comments[doc?.document_id] || ""}
                            onChange={(e) =>
                              handleCommentChange(
                                doc?.document_id,
                                e.target.value
                              )
                            }
                            disabled={
                              !showCommentsInputs[doc?.document_id] ||
                              isDisabled
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}

                <tr>
                  <td colSpan="3">
                    <Button
                      type="submit"
                      className="btn btn-sm btn_green_yellow me-2"
                      variant="green_yellow"
                      style={{ float: "right" }}
                      onClick={handleRequestAdditionalInfoModalOpen}
                      disabled={requestAddBTN_Disabled || is_doc_deleted == "1"}
                    >
                      Request Additional Info
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>

            {ApiPageData?.first_btn_send === true && (
              <div className="col-md-12 my-3 mb-3 mt-1">
                <div className="row">
                  <div className="col-12">
                    <button
                      id="Send_Employment_Verification_Email"
                      className="btn btn_green_yellow"
                      onClick={handleQualificationVerificationModalShow}
                      disabled={is_doc_deleted == "1"}
                    >
                      Send Qualification Verification Email
                    </button>
                  </div>
                </div>
              </div>
            )}

            {ApiPageData?.second_btn_upload === true && (
              <div className="col-12 my-3 mt-1">
                <form
                  id="verify_email_stage_1"
                  method="post"
                  onSubmit={handleSubmitVerificationQualificationDocument}
                >
                  <h6>Verification - Qualification</h6>
                  <div className="row">
                    <div className="col-8 col-md-4 d-flex">
                      <input
                        name="file"
                        type="file"
                        className="form-control s1"
                        required
                        onChange={handleFileChangeForVerificationQualification}
                        accept=".pdf"
                        style={{height:"35px"}}
                        disabled={is_doc_deleted == "1"}
                      />
                    </div>
                    <div className="col-2 col-md-2">
                      <button
                        onClick={handleSubmitVerificationQualificationDocument}
                        type="submit"
                        className="btn btn_green_yellow"
                        disabled={is_doc_deleted == "1"}
                      >
                        Upload
                      </button>
                    </div>
                    <div className="text-danger">
                      {formValues.error && <span>{formValues.error}</span>}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </>
        </Accordion.Body>
      </Accordion.Item>

      <EnsureFileDownloadModal
        show={showEnsureFileDownloadModal}
        onHide={handleEnsureFileDownloadClose}
        stage={stage}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
      />
      <DeleteFileModal
        show={showDeleteFileModal}
        onHide={handleDeleteFileClose}
        selectedDoc={selectedDoc}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
        stage={stage}
      />
      <AddImageModal
        current_stage = {current_stage}
        show={showAddImageModal}
        onHide={handleAddImageClose}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
        stage={stage}
      />
      <EditImageModal
        isRequired = {isRequired}
        show={showEditImageModal}
        onHide={handleEditImageClose}
        selectedDoc={selectedDoc}
        pointerID={pointerID}
        getAllDataOfSubmittedApplicationsFunction={
          getAllDataOfSubmittedApplicationsFunction
        }
        stage={stage}
      />

      {showQualificationVerificationModal && (
        <QualificationVerificationModal
          show={showQualificationVerificationModal}
          handleClose={handleQualificationVerificationModalClose}
          pointerID={pointerID}
          getAllDataOfSubmittedApplicationsFunction={
            getAllDataOfSubmittedApplicationsFunction
          }
          stage={stage}
        />
      )}

      {showVerificationModal && (
        <VerificationModal
          show={showVerificationModal}
          onClose={handleVerificationModalClose}
          onYes={handleVerificationModalYes}
          Title="Confirm !"
          Message={
            <>
              Are you sure you want to request{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                Additional Information
              </span>
              ?
            </>
          }
        />
      )}

      {showTRAModal && (
        <VerificationModal
          show={showTRAModal}
          onClose={handleTRAClose}
          onYes={() => handleRequestAgainTRA(selectedDoc)}
          Title="Confirm !"
          Message={
            <>
              Are you sure you want to request again{" "}
              <span style={{ fontWeight: "bold", color: "#055837" }}>
                TRA Application Form
              </span>
              ?
            </>
          }
        />
      )}
    </>
  );
};

export default Stage_3_R;
